import axios from '@/config/axios'
import { message } from 'ant-design-vue'

export default {
    /*init({commit}, {data}) {
        commit('SET_MENU', data)
        commit('PAGE_ROUTE_GENERATE', data)
        commit('FORM_ROUTE_GENERATE', data)
    },*/
    routeInit({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/app_info/routes/', {
                params: {
                    ver: 'alt'
                }
            })
                .then(({ data }) => {
                    if(data)
                        commit('SET_ROUTER_INIT', data)

                    resolve(true)
                })
                .catch(e => {
                    console.log(e, 'route init')
                    reject(e)
                })
        })
    },
    changeMobileRouteList({state}) {
        return new Promise((resolve, reject) => {
            const key = 'route_mob_loading'
            message.loading({content: 'Сохранение', key})

            const newRouteOrder = {}
            state.routerMobile.forEach((route, index) => {
                newRouteOrder[route.name] = {
                    isFooter: route.isFooter,
                    isShow: route.isShow,
                    isShowMobile: route.isShowMobile,
                    mobileOrder: index,
                    descOrder: route.descOrder
                }
            })

            axios.post('/app_info/routes/custom/', newRouteOrder)
                .then(({ data }) => {
                    message.success({content: 'Сохранено', key})
                    resolve(true)
                })
                .catch(e => {
                    console.log(e, 'changeRouterList init')
                    message.error({content: 'Ошибка сохранения', key})
                    reject(e)
                })
        })
    },
    changeRouterList({ commit }, value) {
        return new Promise((resolve, reject) => {
            const key = 'route_loading'
            message.loading({content: 'Сохранение', key})

            const newRouteOrder = {}
            value.forEach((route, index) => {
                newRouteOrder[route.name] = {
                    isFooter: route.isFooter,
                    isShow: route.isShow,
                    isShowMobile: route.isShowMobile,
                    mobileOrder: route.mobileOrder,
                    descOrder: index
                }
            })

            commit('CHANGE_ROUTER_LIST', value)
            axios.post('/app_info/routes/custom/', newRouteOrder)
                .then(({ data }) => {
                    message.success({content: 'Сохранено', key})
                    resolve(true)
                })
                .catch(e => {
                    console.log(e, 'changeRouterList init')
                    message.error({content: 'Ошибка сохранения', key})
                    reject(e)
                })
        })
    },
    getRouterInfo({ commit }, { name }) {
        return new Promise((resolve, reject) => {
            axios.get('app_info/routes/meta/', {
                params: {
                    name
                }
            }).
                then(({ data }) => {
                    if(Object.keys(data)?.length) {
                        commit('SET_ROUTE_INFO', data)
                    }
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}