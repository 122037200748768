const documentReverseStorage = JSON.parse(localStorage.getItem('documentReverse'))
const eyeVersionStorage = JSON.parse(localStorage.getItem('eyeVersionStorage'))
const asideTypeStorage = JSON.parse(localStorage.getItem('asideType'))

export default () => ({
    windowWidth: null,
    windowHeight: null,
    platform: null,
    wrapperPadding: true,
    documentReverse: documentReverseStorage ? documentReverseStorage : false,
    online: true,
    loginProgress: 0,
    cacheUID: localStorage.getItem('cacheUID') ? localStorage.getItem('cacheUID') : null,
    dbList: ['config', 'sort', 'task', 'order', 'groups', 'table'],
    storageList: ['user', 'task_create_form_draft'],
    serverType: process.env.NODE_ENV,
    eyeVersion: eyeVersionStorage ? eyeVersionStorage : false,
    swUpdate: false,
    swRegistration: null,
    pushAuth: null,
    visibilityState: true,
    pwaCounter: {},
    deferredPrompt: null,
    isMobile: false,
    asideType: asideTypeStorage ? asideTypeStorage : 'mini',
    // 
    connectedModules: [],
    connectedRooms: []
})